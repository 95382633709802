import { defineStore } from 'pinia';
import type { ID } from '@repo/style3d';
import { ref } from 'vue';


export const useSelectionStore = defineStore('selection', () => {
  const selectedFabricId = ref<ID>('');

  function setSelectedFabric(id: ID) {
    selectedFabricId.value = id;
  }
  return {
    setSelectedFabric,
    selectedFabricId
  };
});
