<script setup lang="ts">
</script>
<template>
  <div class="footer">
    Style3D针织软件提供技术支持
  </div>
</template>
<style scoped>
.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
